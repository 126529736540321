<template>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="purple darken-2" dark>
        <v-app-bar-nav-icon @click.prevent.stop="toggleSidebar"></v-app-bar-nav-icon>
        <v-toolbar-title style="width: 260px" class="ml-0 pl-4">
            <span class="hidden-sm-and-down">ВПР. Центр управления</span>
        </v-toolbar-title>
        <div class="d-flex align-end h-50">
            <span v-if="!loading && items.length" class="text-caption hidden-sm-and-down">{{ getLicensesStatusInfo(items) }}</span>
        </div>
        <v-spacer></v-spacer>
        <span v-if="profile">{{ profile.name || profile.login }}</span>
        <v-btn icon @click.prevent.stop="logout">
            <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'HeadBar',
    computed: {
        ...mapState('user', ['profile']),
        ...mapState('license', ['items', 'loading']),
    },
    methods: {
        toggleSidebar() {
            this.$emit('toggle-sidebar', 1);
        },
        async logout() {
            await this.$store.dispatch('user/logout');
            this.$router.go('/');
        },
        getLicensesStatusInfo(licenses) {
            if (!licenses) { return; }

            for (const license of licenses)
                if (this.isLicenseActive(license) && !this.isLicenseExpiresSoon(license))
                    return 'Активировано';

            for (const license of licenses)
                if (!this.isLicenseExpired(license) && this.isLicenseExpiresSoon(license))
                    return 'Активировано. Срок действия почти истек.';

            return 'Нет активированных лицензий';
        },
        isLicenseExpiresSoon(item) {
            const now = parseInt(Date.now() / 1000);
            const monthInSeconds = 60 * 60 * 24 * 31;
            return now + monthInSeconds > item.expires_at;
        },
        isLicenseExpired(item) {
            const now = parseInt(Date.now() / 1000);
            return now > item.expires_at;
        },
        isLicenseActive(item) {
            if (!item.active) { return false; }
            return !this.isLicenseExpired(item);
        }
    }
}
</script>