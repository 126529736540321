<template>
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app dark :color="$const.color.primary">
        <v-list dense>
            <template v-for="(item, i) in items">
                <v-divider v-if="item.border_top" :key="`divider${i}`" />
                <item :item="item" :key="i" />
            </template>
        </v-list>
    </v-navigation-drawer>
</template>
<script>

import Item from './Item.vue'

export default {
    name: 'Sidebar',
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: { Item },
    data() {
        return {
            items: [
                { heading: 'Состояние'},
                { icon: 'mdi-home', text: 'Главная', href: '/home' },
                { icon: 'mdi-shield-account', text: 'Лицензии', href: '/license' },
                { heading: 'Управление'},
                { icon: 'mdi-card-account-details-outline', text: 'Классы', href: '/group' },
                { icon: 'mdi-account-arrow-left-outline', text: 'Преподаватели', href: '/user?role=teacher' },
                { icon: 'mdi-account-arrow-left-outline', text: 'Учащиеся', href: '/user?role=student' },
                { icon: 'mdi-logout-variant', text: 'Выйти', handler: async () => {
                    await this.$store.dispatch('user/logout');
                    return this.$router.go('/').catch(() => {});
                }}
            ]
        }
    },
    computed: {
        drawer: {
            get: function(){
                return this.visible;
            },
            set: function(newValue){
                this.$emit('update:visible', newValue)
            }   
        },
    }
}
</script>